import React from 'react';
import TopMenu from '../common/topMenu';
import Game from '../common/tictactoe';
import BodyTracking from '../contentPages/bodyTracking';
import PersonalRecords from '../contentPages/personalRecords';
import 'purecss/build/pure.css';


export default class Layout extends React.Component {
    constructor(props) {
        super(props);
        this.state = { contentToShow: <Game/> };
    }

    menuList = [<Game />, <BodyTracking />, <PersonalRecords />];

    changeContent = (menuIndexFromCallback) => {
        this.setState({ contentToShow: this.menuList[menuIndexFromCallback]});
    }

    render() {
        return (
            
            <div>
                <div className='header'></div>
                <div id='span'></div>
                <div id='Menu' className='pure-menu pure-menu-horizontal'><TopMenu parentCallback={this.changeContent} /></div>
                <div id='Content'>{this.state.contentToShow}</div>
                <div style={{background: 'blue'}}>Blue</div>
            </div>
        );
    }
}
