import React from 'react';
import logo from '../otter.png';

export default class TopMenu extends React.Component {
    sendContent = (content) => {
        this.props.parentCallback(content);
    }
    render() {
        return (
            <div className="topMenu" class="pure-menu pure-menu-horizontal">
                <ul class="pure-menu-list">
                    <li class="pure-menu-item pure-menu-selected"><a href="https://www.workotters.com" class="pure-menu-link"><img Height='50px' src={logo}/></a></li>
                    
                        <li class="pure-menu-item" onClick={() => { this.sendContent(0) }}><a href="#Game" class="pure-menu-link">Game</a></li>
                        <li class="pure-menu-item" onClick={() => { this.sendContent(1) }}><a href="#BodyTracking" class="pure-menu-link">Body Tracking</a></li>
                        <li class="pure-menu-item" onClick={() => { this.sendContent(2) }}><a href="#PRs" class="pure-menu-link">PRs</a></li>
                    
                </ul>
            </div>
        );
    }
}